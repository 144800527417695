import React from 'react'
import Navbar from '../Navbar/Navbar'

import './Contactus.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FaFacebook } from 'react-icons/fa';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

// import Footer from '../Footer'

function Contactus() {
  return (
    <>
      <div>
        <div>
          <Navbar />
        </div>
        <div>
          <body style={{fontFamily:"krona one"}}>

            <div class="containerr ">

              {/* <span class="big-circle"></span> */}
              <img src="img/shape.png" class="square" alt="" />
              <div class="form">
                <div class="contact-info">
                  <h3 style={{color:"#f2b40b" , fontSize:"28px" , fontWeight:"bold"}} class="title">Let's get in touch</h3>
                  <p style={{color:"black"}} class="text">
                    Have a query or need assistance? Drop us a message below.

                    We're eager to hear from you! Reach out and let's start a conversation.
                  </p>

                  <div class="info ">
                    <div class="information " >
                      <div className='mb-3'>  <FontAwesomeIcon style={{color:"black"}} icon={faMapMarkerAlt} /> &nbsp; &nbsp;</div>


                      <p style={{color:"black"}}>Newland Phone Shop 213 Newland Ave, Hull HU5 2EN</p>
                    </div>
                    <div class="information">
                      <div className='mb-3'> <FontAwesomeIcon style={{color:"black"}} icon={faEnvelope} />&nbsp; &nbsp;</div>

                      <p style={{color:"black"}}>npshull1@gmail.com</p>
                    </div>
                    <div class="information">
                      <div className='mb-3'> <FontAwesomeIcon style={{color:"black"}} icon={faPhone} />&nbsp;&nbsp;</div>

                      <p style={{color:"black"}}>01482 961626</p>

                    </div>
                  </div>

                  <div class="social-media">
                    <p style={{color:"black" , fontWeight:"bold" , fontSize:"large"}}>Connect with us :</p>
                    <div class="social-icons">
                      <a href="https://www.facebook.com/share/91r2rzKqpzZG4U7b/">
                        <FaFacebook  />
                      </a>
                      {/* <a href="https://www.facebook.com/share/91r2rzKqpzZG4U7b/">
                        <FontAwesomeIcon  icon={faTwitter} />
                      </a> */}
                      <a href="https://www.instagram.com/nps_hull?igsh=bDVxbjJpcDJ2NWY1">
                        <FontAwesomeIcon  icon={faInstagram} />
                      </a>
                      <a href="https://www.tiktok.com/@nps_hull?_t=8p5iou0tQWm&_r=1">
                      <i class="fa-brands fa-tiktok"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="contact-form">
                  <span class="circle one"></span>
                  <span class="circle two"></span>

                  <form action="index.html" autocomplete="off">
                    <h3 style={{textAlign:"center" , color:"black"  , fontWeight:"bold" , fontSize:"32px"}} class="title ">Contact us</h3> <br/>
                    <div class="input-container" style={{ color: "white" }}>
                    <label style={{marginBottom:"0px"}} for="">Full Name</label>
                      <input type="text" name="name" class="input" />
                      

                    </div>
                    <div class="input-container">
                    <label style={{ color: "white" ,marginBottom:"0px" , marginTop:"15px" }} for="">Email</label>
                      <input type="email" name="email" class="input" />
                     

                    </div>
                    <div class="input-container">
                    <label style={{ color: "white" ,marginBottom:"0px", marginTop:"15px"  }} for="">Phone</label>
                      <input type="tel" name="phone" class="input" />
                     

                    </div>
                    <div class="input-container textarea">
                    <label style={{ color: "white" ,marginBottom:"0px" , marginTop:"15px" }} for="">Message</label>
                      <textarea name="message" class="input"></textarea>
                     

                    </div>
                    <input type="submit" value="Send" class="btnn" />
                  </form>
                </div>
              </div>
            </div>

            <script src="app.js"></script>
          </body>
        </div>
      </div>
      <div>



      </div>






    </>
  )
}

export default Contactus